import React, { useContext } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../components/utils/seo';
import Layout from '../components/Layout/Layout';
import IndexImage from '../components/IndexImage';

import { LangContext } from '../context/LangContext';

const H1 = styled.h1`
  margin-top: 7rem;
  font-size: 3.7rem;
  line-height: 130%;
  text-align: center;
  padding: 0 1.5rem;

  @media screen and (max-width: 578px) {
    padding: 0 5rem;
  }

  @media screen and (max-width: 555px) {
    font-size: 3rem;
    padding: 0 3.5rem;
  }

  @media screen and (max-width: 455px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 420px) {
    font-size: 3rem;
  }
`;

const P = styled.p`
  margin-top: 5rem;
  max-width: 70%;
  font-size: 2rem;
  line-height: 140%;
  text-align: center;

  @media screen and (max-width: 475px) {
    font-size: 1.7rem;
    max-width: 80%;
  }
`;

const MapWrapper = styled.div`
  margin-top: 5rem;
  max-width: 110rem;
  width: 110rem;
  height: auto;
  @media screen and (max-width: 1050px) {
    max-width: 80rem;
    width: 80rem;
  }

  @media screen and (max-width: 760px) {
    max-width: 60rem;
    width: 60rem;
  }

  @media screen and (max-width: 555px) {
    max-width: 50rem;
    width: 50rem;
  }

  @media screen and (max-width: 485px) {
    max-width: 40rem;
    width: 40rem;
  }

  @media screen and (max-width: 360px) {
    max-width: 30rem;
    width: 30rem;
  }
`;

const MapImage = () => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/mapImage.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <MapWrapper>
      <Img fluid={image.file.childImageSharp.fluid} />
    </MapWrapper>
  );
};

const Title = () => {
  const { lang } = useContext(LangContext);

  return (
    <H1>
      {lang === 'el'
        ? 'Καλώς ήρθατε στο εργοστάσιό μας!'
        : 'Welcome to our factory!'}
    </H1>
  );
};

const LangTestComp = () => {
  const { lang } = useContext(LangContext);

  return (
    <P>
      {lang === 'el'
        ? 'Το εργοστάσιο μας είναι στο χωριό Ανήλιο-Μέτσοβο σε υψόμετρο 1060μ στην θέση Μιάσα Κατούσια (έτσι προφέρεται στη βλάχικη γλώσσα η τοποθεσία όπου βρίσκεται). Η μονάδα μας είναι εδώ και 35 χρόνια σταθερή στην ποιότητα και στην εγγύηση προς τον πελάτη. Με έμπειρο και εξειδικευμένο προσωπικό, που καθημερινά προσπαθεί για το καλύτερο αποτέλεσμα και τις καινούριες ιδέες.Καλώς ορίσατε στο εργοστάσιο μας. Θα χαρούμε να είμαστε δίπλα σας σε κάθε σας ανάγκη. Είναι υποχρέωση μας...'
        : 'Our factory is located at the village Anilio-Metsovo at an altitude of 1060m in Miasa Katiousa (thus the location where it is located is pronounced in the Vlachlanguage. Our unit has been stable in quality and guarantee to the customer for 35 years. With experienced and specialized staff, who daily strives for the best result and new ideas.'}
    </P>
  );
};

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Title />
      <IndexImage />
      <LangTestComp />
      <a
        href="https://www.google.com/maps/search/?api=1&query=39.758112%2C21.180958"
        target="_blank"
      >
        <MapImage />
      </a>
    </Layout>
  );
};
export default IndexPage;
