import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const ImageWrapper = styled.div`
  margin-top: 4rem;
  max-width: 110rem;
  width: 110rem;
  height: auto;

  @media screen and (max-width: 1050px) {
    max-width: 80rem;
    width: 80rem;
  }

  @media screen and (max-width: 760px) {
    max-width: 60rem;
    width: 60rem;
  }

  @media screen and (max-width: 555px) {
    max-width: 50rem;
    width: 50rem;
  }

  @media screen and (max-width: 485px) {
    max-width: 40rem;
    width: 40rem;
  }

  @media screen and (max-width: 360px) {
    max-width: 30rem;
    width: 30rem;
  }
`;

const IndexImage = () => {
  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/index.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1100, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <ImageWrapper>
      <Img fluid={image.file.childImageSharp.fluid} />
    </ImageWrapper>
  );
};

export default IndexImage;
